import './scss/index.scss';
import { Outlet, useNavigate } from "react-router-dom";
import Maps from "./assets/icon/mapsNew.svg";
import MapsPoints from "./assets/icon/pointsNew.svg";
import Left from "./assets/icon/left.svg";
import Carbon_location from "./assets/icon/carbon_location-current.svg";
import TapBar from "../../components/tapBar";
import React, { useState, useEffect, useRef } from 'react';
import StateSlices from "../../redux/slices/State";
import { useSelector } from "react-redux";

export default function AboutRoute() {
    const state_in = useSelector(StateSlices);
    const citySelect = state_in.city_select;
    const bgAll = state_in.bgAll;
    const bgSelect = state_in.bgSelect;
    const previousOrientation = useRef(false);
    const navigate = useNavigate();
    const [heading, setHeading] = useState(0);

    const [hrefC, setHrefC] = useState('');

    let elBG = bgAll.find(bg => bg.id === bgSelect);
    if (!elBG) {
        elBG = bgAll[0];
    }

    // Function to handle direction change
    const handleDirectionChange = (direction) => {
        setHeading(direction);
    };

    // useEffect to update direction based on URL hash
    useEffect(() => {
        const handleHashChange = () => {
            // Get hash value from URL, e.g., #direction=90
            const hashValue = window.location.hash;
            if (hashValue && hashValue.includes('direction')) {

                // Extract direction value from hash
                const direction = parseInt(hashValue.split('=')[1]);
                // Adjust direction so that 0 degrees points north (top of the screen)
                const adjustedDirection = (direction + 90) % 360;
                handleDirectionChange(adjustedDirection);
            }
        };

        // Set event listener for hash change
        window.addEventListener('hashchange', handleHashChange);

        // Call handler on component mount
        handleHashChange();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);





    var lat1 = 43.242820085543215;
    var long1 = 76.89187095045924;


    var lat2 = 21.42036545333037;
    var long2 = 39.82493610722002;


    var dy = lat2 - lat1;
    var dx = Math.cos(Math.PI / 180 * lat1) * (long2 - long1);
    var angle = ((Math.atan2(dy, dx)) * 100);


    return (
        <div className={'page page-bk'} style={{ backgroundImage: "url(data:image/png;base64," + elBG?.images + ")" }}>
            <div className="page__main-content">
                {hrefC}
                <div className="page-bk-head">
                    <p onClick={() => navigate('/time')} className={'text icon-center-pos text-s15'}>
                        <img alt={'maps'} className={'page-icon page-icon-mini'} src={Left} />
                        <span className={'text text-s22'}>Кибла</span>
                    </p>
                    <img alt={'maps'} className={'page-icon'} src={Carbon_location} />
                </div>

                <div className="compos-element">

                    <img
                        alt={'maps'}
                        style={{transition: "0.3s", transform: `rotate(${(heading) * (-1)}deg)`}}
                        className={'icon-compos-cursor'}
                        src={Maps}
                    />
                    <img
                        alt={'maps'}
                        style={{
                            transform: `rotate(${(360 - heading - 225) + angle}deg)`
                        }}
                        className={'icon-compos-cursor icon-compos-cursor-absolut'}
                        src={MapsPoints}
                    />
                </div>

                <TapBar/>
                <Outlet/>
            </div>
        </div>
    );
}
